import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeviceManagement from '../hooks/useDeviceManagement';
import { addFirmware, setFirmware, updateFirmware } from '../redux/firmware';
import { setState, setStatus, setBuild, setImageSrc } from '../redux/device';
import { addPayment } from '../redux/user';
import { getFirmware } from '../api/devices';
import { toast as make_toast } from 'react-toastify';
import { WS_URL } from '../constants/constants';
import useUserData from './useUserData';

export default function useWebSocket() {
  const dispatch = useDispatch();
  const { refreshDevices } = useDeviceManagement();
  const { refetchUserData } = useUserData();

  // Selectors
  const deviceId = useSelector((state) => state.device.deviceId);
  const activeView = useSelector((state) => state.user.activeView);
  const firmware = useSelector((state) => state.firmware.firmware);

  // Refs for websocket handlers
  const deviceIdRef = useRef(deviceId);
  const activeViewRef = useRef(activeView);
  const firmwareRef = useRef([]);

  // Keep refs in sync with state
  useEffect(() => {
    deviceIdRef.current = deviceId;
  }, [deviceId]);
  useEffect(() => {
    activeViewRef.current = activeView;
  }, [activeView]);
  useEffect(() => {
    firmwareRef.current = firmware;
  }, [firmware]);

  const messageHandlers = {
    firmware: async (payload) => {
      if (!deviceIdRef.current) {
        return;
      }

      const { firmware_id } = payload;
      const existingFirmware = firmwareRef.current.find(
        (f) => f.id === firmware_id.toString()
      );

      if (!existingFirmware) {
        try {
          const firmware = await getFirmware(deviceIdRef.current);
          dispatch(setFirmware(firmware));
        } catch (error) {
          console.error('Error fetching firmware details:', error);
        }
      } else {
        dispatch(addFirmware(payload));
      }
    },

    update_firmware: async (data) => {
      const firmware = await getFirmware(data.deviceId);
      dispatch(setFirmware(firmware));
    },

    update_devices: async (data) => {
      refreshDevices()
    },

    update_userdata: async (data) => {
      refetchUserData()
    },

    image: (data) => {
      // if (
      //   data?.device_id &&
      //   data?.payload &&
      //   data.device_id === deviceIdRef.current
      // ) {
      // const blob = new Blob([data], { type: 'image/jpeg' });
      // const url = URL.createObjectURL(blob);
      // dispatch(setImageSrc(data));
      // }
    },

    state: (data) => {
      if (
        data?.device_id &&
        data?.payload &&
        data.device_id === deviceIdRef.current
      ) {
        dispatch(setState(JSON.parse(data?.payload)));
      }
    },

    status: (data) => {
      if (data?.device_id) {
        dispatch(setStatus(data));
      }
    },

    build: (data) => {
      if (data?.device_id) {
        dispatch(setBuild(data));
      }
    },

    payment: (data) => {
      if (activeViewRef.current === 'credits') {
        dispatch(addPayment(data));
      }
    },

    toast: (data) => {
      console.log(data);
      if (data?.msg_type === 'error' || data?.error) {
        make_toast.error(data.message);
      } else {
        make_toast.success(data.message);
      }
    },
  };

  const connectWebSocket = (channel) => {
    let socket = new WebSocket(WS_URL);
    let retryCount = 0;
    const maxRetries = 5;

    socket.onopen = () => {
      console.log('WebSocket connection opened.');
      retryCount = 0;
      socket.send(
        JSON.stringify({ command: 'join', args: { channel: channel } })
      );
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const handler = messageHandlers[data.type];
      if (handler) {
        handler(data?.payload ? data.payload : data?.data ? data.data : data);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed.');
      if (retryCount < maxRetries) {
        const retryDelay = Math.min(100 * Math.pow(2, retryCount), 30000);
        retryCount++;
        setTimeout(() => connectWebSocket(channel), retryDelay);
      } else {
        console.log(
          'Error reconnecting to WebSocket. Please contact support or try again later.'
        );
      }
    };

    return () => {
      socket.close();
    };
  };

  return { connectWebSocket };
}
