import React, { useState } from 'react';
import ModalLayout from './ModalLayout';
import useDeviceManagement from '../../hooks/useDeviceManagement';

const availablePlatforms = ['ESP8266', 'ESP32'];

function DeviceCreationModal({ closeDeviceCreationModal }) {
  const [platform, setPlatform] = useState('ESP8266');
  const [name, setName] = useState('');
  const [wifi, setWifi] = useState('');
  const [wifiPassword, setWifiPassword] = useState('');
  const { addNewDevice } = useDeviceManagement();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addNewDevice(platform, name, wifi, wifiPassword);
    closeDeviceCreationModal();
  };

  return (
    <ModalLayout closeModal={closeDeviceCreationModal}>
      <div className="space-y-6">
        <h2 className="text-lg font-medium">Добавление устройства</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Платформа
              </label>
              <div className="mt-1 flex space-x-2">
                {availablePlatforms.map((unit) => (
                  <button
                    key={unit}
                    onClick={() => setPlatform(unit)}
                    className={`px-4 py-2  text-sm font-medium focus:outline-none ${
                      platform === unit
                        ? 'bg-blue-100 border border-sky-500 text-blue-900'
                        : 'bg-gray-200 border text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {unit}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Название устройства
              </label>
              <input
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full rounded border border-gray-200 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                placeholder="Введите название устройства"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Wi-Fi сеть
              </label>
              <input
                type="text"
                required
                value={wifi}
                onChange={(e) => setWifi(e.target.value)}
                className="mt-1 block w-full rounded border border-gray-200 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                placeholder="Введите имя Wi-Fi сети"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Пароль Wi-Fi
              </label>
              <input
                type="text"
                required
                value={wifiPassword}
                onChange={(e) => setWifiPassword(e.target.value)}
                className="mt-1 block w-full rounded border border-gray-200 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                placeholder="Введите пароль Wi-Fi"
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-6 w-full rounded border bg-blue-50 py-2 text-sm font-medium text-blue-700 hover:bg-blue-100"
          >
            Добавить устройство
          </button>
        </form>

        <div className="rounded border border-gray-200 bg-gray-50 p-3">
          <h3 className="mb-2 font-medium text-gray-700">Примечание:</h3>
          <ul className="space-y-1 text-sm text-gray-600">
            <li>
              Wi-Fi сеть и пароль нужны для подключения устройства к платформе
            </li>
            <li>
              После добавления устройства начнется процесс компиляции
              демонстрационной прошивки
            </li>
          </ul>
        </div>
      </div>
    </ModalLayout>
  );
}

export default DeviceCreationModal;
