import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFQBN, getUserData } from '../api/devices';
import { setInfo, setToken } from '../redux/user';
import { fetchDevices } from '../api/devices';
import { setAvailableFqbn, setDefaultFqbn, setDevices } from '../redux/device';
import { openModal } from '../redux/modals';
import { oauth } from '../api/login';

const AppContext = createContext(null);

export function AppProvider({ children }) {
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();

  const openLogin = () => dispatch(openModal('loginModal'));
  const updateToken = (t) => dispatch(setToken(t));

  oauth(openLogin, updateToken);

  useEffect(() => {
    const initializeApp = async () => {
      const user = localStorage.getItem('access_token');
      if (user) {
        try {
          const [userData, devices, fqbn] = await Promise.all([
            getUserData(),
            fetchDevices(),
            fetchFQBN()
          ]);

          dispatch(setInfo(userData));
          dispatch(setDevices(devices));
          dispatch(setDefaultFqbn(fqbn.default_fqbn));
          dispatch(setAvailableFqbn(fqbn.available_fqbn));
        } catch (error) {
          console.error('Error initializing app:', error);
        } finally {
          setIsInitialized(true);
        }
      } else {
        setIsInitialized(true);
      }
    };

    initializeApp();
  }, [dispatch]);

  return (
    <AppContext.Provider value={{ isInitialized, setIsInitialized }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
}
