import React, { useState, useEffect, useCallback } from 'react';
import './Ide.css';
import '../Devices/ESPInstall';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { githubLight } from '@uiw/codemirror-theme-github';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile, generateFirmware, getFirmware } from '../../api/devices';
import throttle from 'lodash.throttle';
import ESPInstall from '../Devices/ESPInstall';
import { setFirmware } from '../../redux/firmware';
import { toast } from 'react-toastify';

function Ide({ handleFileChange, file, deviceId }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchFileContent = async (file) => {
    try {
      const response = await fetch(file);
      if (!response.ok) throw new Error('Network response was not ok');
      const text = await response.text();
      setValue(text);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFileContent(file);
  }, [file]);

  // Throttled upload function
  const throttledUploadFile = useCallback(
    throttle((content) => {
      uploadFile(content, deviceId)
        .then((response) => {
          console.log('File uploaded successfully:', response);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    }, 5000),
    [deviceId]
  );

  const onChange = useCallback((newValue) => {
    setValue(newValue);
    throttledUploadFile(newValue);
  }, []);

  const handleSave = () => {
    throttledUploadFile(value);
  };

  const handleGenerateCode = async () => {
    try {
      await generateFirmware(deviceId, prompt);
    } catch {
      toast.error('Ошибка генерациии');
    }
  };

  // Handle Download as File
  const handleDownload = () => {
    const blob = new Blob([value], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file && file?.name ? file.name : 'iopp_firmare.ino';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="mt-4 flex items-center space-x-2">
        <div className="w-full items-center rounded-xl border bg-zinc-100 px-4 py-1 text-center hover:bg-zinc-200 lg:w-fit">
          <input
            type="file"
            id="firmwareFile"
            className="hidden"
            onChange={handleFileChange}
          />
          <label htmlFor="firmwareFile" className="cursor-pointer">
            Загрузить файл
          </label>
        </div>

        <button
          onClick={handleDownload}
          className="w-full items-center rounded-xl bg-blue-100 px-4 py-1 text-center hover:bg-blue-200 lg:w-fit"
        >
          Скачать файл
        </button>
        <button
          onClick={handleSave}
          className="w-full items-center rounded-xl border bg-green-100 px-4 py-1 text-center hover:bg-green-200 lg:w-fit"
        >
          Сохранить изменения
        </button>
      </div>

      <div className="mt-4 flex items-center space-x-2">
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Введите ваш запрос (например 'Мне нужно, чтобы устройство считывало данные с DHT11')"
          className="w-full rounded-xl border bg-white px-4 py-1"
        />
        <button
          onClick={handleGenerateCode}
          disabled={isLoading}
          className="w-full text-nowrap items-center rounded-xl bg-purple-100 px-4 py-1 text-center hover:bg-purple-200 lg:w-fit"
        >
          {isLoading ? 'Генерация...' : 'Сгенерировать прошивку'}
        </button>
      </div>

      {/* CodeMirror Editor */}
      <CodeMirror
        theme={githubLight}
        value={value}
        height="100%"
        className="codemirror-editor mt-4 w-full overflow-x-auto text-sm"
        extensions={[javascript({ jsx: true })]}
        onChange={onChange} // Ensure onChange is correctly passed
      />
    </>
  );
}

export default Ide;
