import React from 'react';

const PageNotFound = () => {
  return (
    <div className="flex flex-col gap-5 border-2 bg-white shadow-[inset_0_-2px_1px_1px_rgba(0,0,0,0.35)] p-2">
      <h1>404 Error</h1>
      <h1>Страница не найдена</h1>
      <div className="border border-gray-300 p-2 w-full text-center hover:bg-slate-100 shadow-[inset_0_-2px_1px_1px_rgba(0,0,0,0.15)]">
        <a href="/">На главную</a>
      </div>
    </div>
  );
};

export default PageNotFound;
