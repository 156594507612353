import React, { useEffect } from 'react';
import './App.css';
import Lending from './pages/Lending/Lending';
import { Routes, Route, Navigate } from 'react-router-dom';
import ControlPage from './pages/ControlPage/ControlPage';
import PageNotFound from './components/NotFound';
import Login from './pages/Login';
import useWebSocket from './hooks/useWebSocket';
import { useAppContext } from './contexts/AppContext';

function App() {
  const websocket_channel = localStorage.getItem('websocket_channel');
  const { connectWebSocket } = useWebSocket();
  const { isInitialized } = useAppContext();
  const isAuthenticated = !!localStorage.getItem('access_token');

  // WebSocket connection
  useEffect(() => {
    if (websocket_channel && isInitialized) {
      connectWebSocket(websocket_channel);
    }
  }, [websocket_channel, isInitialized]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? <Navigate to="/control" replace /> : <Lending />
        }
      />
      <Route
        path="/login"
        element={
          isAuthenticated ? <Navigate to="/control" replace /> : <Login />
        }
      />
      <Route
        path="/control"
        element={
          isAuthenticated ? <ControlPage /> : <Navigate to="/" replace />
        }
      />
      <Route path="*" element={<Navigate to="/404" replace />} status={404} />
      <Route path="/404" element={<PageNotFound />} status={404} />
    </Routes>
  );
}

export default App;
