import React, { useState } from 'react';

function Questions({ isOpen, isClosingInstantly, onToggle, item }) {
  return (
    <div className="faq-item w-full rounded-sm border">
      <div>
        <button
          className={`w-full border border-gray-400 bg-white p-4 text-left font-medium text-gray-800 shadow-sm transition-all hover:bg-zinc-100 ${
            isOpen ? 'rounded-b-none' : ''
          }`}
          onClick={onToggle}
        >
          {item?.buttonText}
        </button>
        <div
          className="faq-answer overflow-hidden"
          style={{
            maxHeight: isOpen ? '500px' : '0',
            opacity: isOpen ? '1' : '0',
            transition: isClosingInstantly
              ? 'max-height 0.2s ease, opacity 0.3s ease'
              : 'max-height 0.4s ease, opacity 0.4s ease',
          }}
        >
          <div className="rounded-b-lg border-t border-neutral-200 bg-neutral-50 p-4">
            {item?.text1 && (
              <p className="text-sm text-gray-700">{item?.text1}</p>
            )}
            {item?.text2 && <hr className="my-2 border-neutral-200" />}
            {item?.text2 && (
              <p className="text-sm text-gray-700">{item?.text2}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Questions;
