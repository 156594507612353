import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeviceModal from '../../components/Modals/DeviceModal';
import FirmwareInfoModal from '../../components/Modals/FirmwareInfoModal';
import DeviceCreationModal from '../../components/Modals/DeviceCreationModal';
import EventLogModal from '../../components/Modals/EventLogModal';
import DeviceStateModal from '../../components/Modals/DeviceStateModal';
import useModalManagement from '../../hooks/useModalManagement';
import FirmwareBuildModal from '../../components/Modals/FirmwareBuildModal';
import useDeviceManagement from '../../hooks/useDeviceManagement';
import LoginModal from '../../components/Modals/LoginModal';
import { reset } from '../../redux/device';

const Modals = () => {
  const dispatch = useDispatch();
  const { modals, handleCloseModal } = useModalManagement();
  const { removeDevice } = useDeviceManagement();
  const { deviceId } = useSelector((state) => state.device);
  const { firmware } = useSelector((state) => state.firmware);
  const firstFirmwareWithBuilds = firmware?.find(
    (fw) => fw?.builds?.length > 0
  );
  const closeDeviceModal = () => {
    handleCloseModal('deviceModal');
    dispatch(reset());
  };

  return (
    <>
      {modals.deviceModal && (
        <DeviceModal
          closeDeviceModal={closeDeviceModal}
          deviceId={deviceId}
          firmwareInfo={firmware}
          removeDevice={removeDevice}
        />
      )}
      {modals.deviceCreationModal && (
        <DeviceCreationModal
          closeDeviceCreationModal={() =>
            handleCloseModal('deviceCreationModal')
          }
        />
      )}
      {modals.firmwareInfoModal && (
        <FirmwareInfoModal
          deviceId={deviceId}
          firmwareInfo={firmware}
          closeFirmwareInfoModal={() => handleCloseModal('firmwareInfoModal')}
        />
      )}
      {modals.buildModal && (
        <FirmwareBuildModal
          deviceId={deviceId}
          previousLibraries={firstFirmwareWithBuilds?.builds?.[0]?.libraries}
          previousFqbn={firstFirmwareWithBuilds?.builds?.[0]?.fqbn}
          closeModal={() => handleCloseModal('buildModal')}
        />
      )}
      {modals.eventLogModal && (
        <EventLogModal
          deviceId={deviceId}
          closeModal={() => handleCloseModal('eventLogModal')}
        />
      )}
      {modals.deviceHistoryModal && (
        <DeviceStateModal
          deviceId={deviceId}
          closeModal={() => handleCloseModal('deviceHistoryModal')}
        />
      )}
    </>
  );
};

export default Modals;
