import React, { useEffect, useRef } from 'react';
import './Lending.css';
import thumbnail from '../../assets/thumbnail.png';
import Hls from 'hls.js';
import { handleAuthButtonClick, oauth } from '../../api/login';
import Layout from '../../components/Layout';
import Logo from '../../components/Logo';
import { accessToken } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../../components/Modals/LoginModal';
import useModalManagement from '../../hooks/useModalManagement';
import { openModal } from '../../redux/modals';
import { useDispatch, useSelector } from 'react-redux';
import useUserData from '../../hooks/useUserData';
import { setToken } from '../../redux/user';

function Lending() {
  const videoPlayerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const { modals, handleCloseModal } = useModalManagement();
  const faqItems = [
    {
      answer: `заполните название, логин и пароль от локальной сети Wi-Fi. После нажатия на кнопку начнется процесс компиляции демонстрационной прошивки. По окончанию компиляции подключите устройство по USB и нажмите на кнопку "Установить прошивку".`,
      title: 'Как добавить новое устройство?',
    },
    {
      answer: `чтобы обновить прошивку, мы можете модифицировать базовую прошивку устройства самостоятельно или используя ИИ, скомпилировать в облаке, загрузить на устройство по воздуху`,
      title: 'Как обновить прошивку устройства?',
    },
    {
      answer: `гибкий веб-интерфейс подстраивается под вашу прошивку, позволяя управлять состоянием устройства. Также можно управлять через приложение яндекса`,
      title: 'Как взаимодействовать с устройством?',
    },
  ];

  const openLogin = () => dispatch(openModal('loginModal'));
  const updateToken = (t) => dispatch(setToken(t));

  useEffect(() => {
    const videoPlayer = videoPlayerRef.current;

    if (videoPlayer) {
      const url = 'https://iopp.io/demo-video/demo.m3u8';
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(videoPlayer);
      } else if (videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
        videoPlayer.src = url;
      }
    }
  }, []);

  return (
    <Layout header="false">
      <div
        className="min-h-vh flex min-h-[10dvh] flex-col items-center justify-center text-center pt-[50px]"
        id="logo-container"
      >
        <div id="logo-landing" className="rounded">
          <Logo />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center py-[30px] gap-8">
        {/* Log in Button */}
        <div>
          <button
            onClick={() => handleAuthButtonClick(openLogin, updateToken)}
            id="login-button"
            className="login-button px-10 border border-black bg-white text-[2em] hover:text-green-500"
          >
            log in
          </button>
        </div>

        {/* Video Player */}
        <div className="w-full flex justify-center pt-[100px]">
          <video
            ref={videoPlayerRef}
            id="videoPlayer"
            controls
            poster={thumbnail}
            className="w-full max-w-[100%] lg:max-w-[75%] h-auto bg-[#000F000A] shadow-xl"
          />
        </div>
      </div>
      <div className="p-5 pt-0">
        <h2 id="about">iopp.io</h2>
        <p>
          универсальный облачный сервис, который позволяет легко подключать,
          управлять и мониторить устройства на базе ESP (ESP8266, ESP32) в
          рамках ваших DIY проектов. Сервис предлагает широкий спектр функций,
          делая управление умным домом или другими проектами интуитивно понятным
          и удобным
        </p>

        <div className="features mt-5">
          <div className="feature">
            <h3 className="pb-3">простое подключение</h3>
            <ul>
              <li>
                <span className="text-sky-600 font-medium">
                  Компиляция прошивок в облаке
                </span>
              </li>
              <li>Быстрое подключение ESP устройств через веб-интерфейс</li>
              <li>Прошивка устройств через USB или по воздуху (OTA)</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">управление и мониторинг</h3>
            <ul>
              <li>Управление устройствами через веб-интерфейс</li>
              <li>Мониторинг состояния в реальном времени</li>
              <li>Подробная история состояний и событий</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">аналитика и статистика</h3>
            <ul>
              <li>Графики показателей в реальном времени</li>
              <li>История всех состояний устройств</li>
              <li>Журнал событий с фильтрацией</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">интеграции</h3>
            <ul>
              <li>
                Поддержка{' '}
                <span className="yandex-gradient font-medium text-[1.1rem]">
                  Яндекс Алисы
                </span>{' '}
                для управления устройствами
              </li>
              <li>Подключение к мобильному приложению Умный дом</li>
              <li>REST API для внешних интеграций</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">триггеры и оповещения</h3>
            <ul>
              <li>Настройка условий для уведомлений</li>
              <li>Мгновенные оповещения в Telegram</li>
              <li>Гибкая настройка условий срабатывания</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">разработка и отладка</h3>
            <ul>
              <li>Встроенный редактор кода с подсветкой синтаксиса</li>

              <li>
                <span className="font-medium text-indigo-800">
                  AI-помощник для генерации кода
                </span>
              </li>

              <li>Компиляция прошивок в облаке</li>
              <li>Отладка через журнал событий</li>
            </ul>
          </div>
        </div>
        <hr />

        <p className="advantages border">
          iopp.io - это идеальное решение для тех, кто хочет создать умный дом
          или другой проект на базе ESP устройств, не тратя время на сложную
          настройку или программирование
        </p>
        <hr />

        <h2 id="faq">F.A.Q.</h2>
        {faqItems.map((faq) => (
          <div key={faq.id}>
            <div className="mt-5 pb-5">{faq.title}</div>
            <div className="border bg-slate-100 p-2">{faq.answer}</div>
          </div>
        ))}
      </div>
      {modals.loginModal && (
        <LoginModal
          closeModal={() => handleCloseModal('loginModal')}
          token={token}
        />
      )}
    </Layout>
  );
}

export default Lending;
