import { Line } from 'react-chartjs-2';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function hashCode(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

const generateRandomColor = (field) => {
  return `#${intToRGB(hashCode(field))}`;
};

const chartOptions = {
  responsive: true,
  animation: {
    duration: 0,
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      borderWidth: 0,
      radius: 1,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
};

const ChartComponent = ({ data }) => {
  const chartData = {
    labels: data.timestamps.map((ts) => new Date(ts).toLocaleString()),
    datasets: Object.keys(data.fields).map((field) => ({
      label: field,
      data: data.fields[field],
      borderColor: generateRandomColor(field),
      borderWidth: 2,
      fill: false,
      tension: 0.3,
      cubicInterpolationMode: 'monotone',
    })),
  };

  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default ChartComponent;
