import React, { useEffect, useState } from 'react';
import ModalLayout from './ModalLayout';
import { createOrder, fetchTransactions } from '../../api/payment';
import { WalletIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveView, setPayments } from '../../redux/user';

const SkeletonLoading = () => (
  <div className="space-y-4">
    {[...Array(4)].map((_, index) => (
      <div
        key={index}
        className="h-16 w-full animate-pulse rounded bg-gray-100"
      />
    ))}
  </div>
);

const DepositModal = ({ closeModal }) => {
  const [amount, setAmount] = useState(0);

  const handleDeposit = async () => {
    if (amount > 0) {
      const { payment_url } = await createOrder(amount * 100);
      window.open(payment_url, '_blank');
      closeModal();
    } else {
      toast.warning('Некорректная сумма');
    }
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="flex gap-4">
        <input
          type="number"
          placeholder="Сумма"
          className="w-full rounded border border-gray-200 px-3 py-2"
          onChange={(e) => setAmount(e.target.value)}
        />
        <button
          onClick={handleDeposit}
          className="btn-header bg-green-100 hover:bg-green-200"
        >
          Оплатить
        </button>
      </div>
    </ModalLayout>
  );
};

const BalanceModal = ({ closeModal, userData }) => {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.user.payments);
  const activeView = useSelector((state) => state.user.activeView);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(4);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [depositModal, setDepositModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchTransactions(
          (currentPage - 1) * transactionsPerPage,
          transactionsPerPage,
          activeView
        );
        dispatch(setPayments(response.data));
        setTotalPages(Math.ceil(response.total_records / transactionsPerPage));
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, activeView, dispatch, transactionsPerPage]);

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="space-y-6">
        {/* Balance Info */}
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mr-8">
          <div className="rounded border border-gray-200 p-4">
            <div className="text-sm text-gray-500">Баланс</div>
            <div className="text-xl font-mono">{userData.balance} ₽</div>
          </div>
          <div className="rounded border border-gray-200 p-4">
            <div className="text-sm text-gray-500">Расход в сутки</div>
            <div className="text-xl font-mono">{userData.consumption} ₽</div>
          </div>
          <button
            onClick={() => setDepositModal(true)}
            className="btn-header flex items-center justify-center gap-2 bg-cyan-50 hover:bg-green-200"
          >
            <WalletIcon className="h-5 w-5" />
            Пополнить
          </button>
        </div>

        {/* Transaction Type Selector */}
        <div className="flex gap-2">
          <button
            onClick={() => {
              dispatch(setActiveView('credits'));
              setCurrentPage(1);
            }}
            className={`btn-header flex-1 ${
              activeView === 'credits' ? 'bg-blue-50' : ''
            }`}
          >
            Пополнения
          </button>
          <button
            onClick={() => {
              dispatch(setActiveView('debits'));
              setCurrentPage(1);
            }}
            className={`btn-header flex-1 ${
              activeView === 'debits' ? 'bg-blue-50' : ''
            }`}
          >
            Списания
          </button>
        </div>

        {/* Transactions List */}
        <div className="space-y-2">
          {loading ? (
            <SkeletonLoading />
          ) : (
            history.map((entry, index) => (
              <div
                key={index}
                className="flex items-center justify-between rounded border border-gray-100 p-3"
              >
                <div>
                  <div className="font-mono text-lg">{entry.amount} ₽</div>
                  <div className="text-sm text-gray-500">
                    {new Date(entry.date).toLocaleString('ru-RU')}
                  </div>
                  {entry.reason && (
                    <div className="text-sm text-gray-500">{entry.reason}</div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="flex justify-center gap-4">
            <button
              onClick={() => setCurrentPage((p) => p - 1)}
              disabled={currentPage === 1}
              className="btn-header px-4 disabled:opacity-50"
            >
              ←
            </button>
            <span className="flex items-center">
              {currentPage} / {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((p) => p + 1)}
              disabled={currentPage === totalPages}
              className="btn-header px-4 disabled:opacity-50"
            >
              →
            </button>
          </div>
        )}
      </div>

      {depositModal && (
        <DepositModal closeModal={() => setDepositModal(false)} />
      )}
    </ModalLayout>
  );
};

export default BalanceModal;
