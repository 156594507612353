import React, { useState } from 'react';
import ModalLayout from './ModalLayout';
import ESPInstall from '../Devices/ESPInstall';
import { useDispatch, useSelector } from 'react-redux';
import { startOTA, editFirmware, getFirmware } from '../../api/devices';
import { closeModal, openModal } from '../../redux/modals';
import { setFirmware } from '../../redux/firmware';

function FirmwareInfoModal({ deviceId, firmwareInfo, closeFirmwareInfoModal }) {
  const dispatch = useDispatch();
  const { devices } = useSelector((state) => state.device);
  const [expandedBuilds, setExpandedBuilds] = useState({});
  const device = devices.find((d) => d.id === deviceId);
  const toggleBuild = (firmwareIndex, buildIndex) => {
    setExpandedBuilds((prev) => {
      const key = `${firmwareIndex}-${buildIndex}`;
      return { ...prev, [key]: !prev[key] };
    });
  };

  const ota = (firmwareId) => {
    startOTA(firmwareId);
  };

  const openBuildModal = () => {
    dispatch(openModal('buildModal'));
  };

  const handleEditFirmware = async (firmwareId) => {
    await editFirmware(deviceId, firmwareId);
    const firmware = await getFirmware(deviceId);
    dispatch(setFirmware(firmware));
    closeFirmwareInfoModal();
  };

  return (
    <ModalLayout closeModal={closeFirmwareInfoModal}>
      <div className="flex items-center justify-between border-b border-gray-200 p-4">
        <h2 className="text-xl font-semibold text-gray-800">
          Прошивки устройства
        </h2>
      </div>
      <div className="p-2">
        {!firmwareInfo.length && (
          <h3>Для создания прошивки откройте окно &quot;Компиляция&quot;</h3>
        )}
        {firmwareInfo?.map((firmware, firmwareIndex) => (
          <>
            <div key={firmware.id} className="">
              {firmware.builds.map((build, buildIndex) => {
                const key = `${firmwareIndex}-${buildIndex}`;
                const isExpanded = expandedBuilds[key] || !device.current_build;

                return (
                  <div key={buildIndex} className="py-1">
                    <button
                      onClick={() => toggleBuild(firmwareIndex, buildIndex)}
                      className={`relative w-full p-2 text-left ${device.current_build === build.id ? 'bg-sky-100 hover:bg-sky-200' : 'bg-gray-100 hover:bg-gray-200'} rounded-lg transition-colors duration-200`}
                    >
                      <div className="flex items-center gap-2 justify-between mr-10">
                        <span className="text-sm text-gray-700">
                          Сборка от {build.created_at}
                        </span>
                        <span
                          className={`inline-block px-5 py-1 rounded-xl text-sm font-medium ${
                            {
                              Готово: 'bg-green-100 text-green-800',
                              'В очереди':
                                'animate-pulse-bg from-yellow-200 to-yellow-50 text-yellow-800',
                              Ошибка: 'bg-red-100 text-red-800',
                              Компилируется:
                                'animate-pulse-bg from-blue-200 to-blue-50 text-blue-800',
                              'Лимит запросов': 'bg-purple-100 text-purple-800',
                              Установлено: 'bg-cyan-100 text-cyan-800',
                            }[
                              device.current_build === build.id
                                ? 'Установлено'
                                : build.status
                            ] || 'bg-gray-200 text-gray-800' // Fallback for unknown statuses
                          }`}
                        >
                          {device.current_build === build.id
                            ? 'Установлено'
                            : build.status}
                        </span>
                        <span className="text-sm min-w-[200px] text-gray-400 invisible sm:visible">
                          {build.fqbn}
                        </span>
                        <span className="absolute right-5 text-sm text-gray-500">
                          {isExpanded ? '▲' : '▼'}
                        </span>
                      </div>
                    </button>
                    {isExpanded && (
                      <>
                        <div
                          className={`flex gap-2 justify-between flex-col pt-2`}
                        >
                          {build.status === 'Ошибка' && (
                            <p className="p-2 bg-red-50 rounded-md overflow-y-auto">
                              {build.result_log &&
                                build.result_log
                                  .split('\n')
                                  .map(function (item, idx) {
                                    return (
                                      <span key={idx}>
                                        {item}
                                        <br />
                                      </span>
                                    );
                                  })}
                            </p>
                          )}
                          <div
                            className={`flex w-full h-fit flex-col gap-2 md:flex-row`}
                          >
                            <button
                              className="btn-primary"
                              onClick={() => handleEditFirmware(firmware.id)}
                            >
                              Редактировать код
                            </button>
                            <ESPInstall
                              disabled={build.status !== 'Готово'}
                              manifest_url={build.manifest}
                            />
                            <button
                              className={`btn-primary text-nowrap ${device.current_build && build.status === 'Готово' ? '' : 'text-gray-400 hover:bg-white'}`}
                              onClick={() => ota(build.id)}
                              disabled={
                                !device.current_build ||
                                build.status !== 'Готово'
                              }
                            >
                              Прошить Over-The-Air
                            </button>
                            {(build.status === 'Ошибка' ||
                              build.status === 'Лимит запросов') && (
                              <button
                                className="btn-primary"
                                onClick={() => openBuildModal()}
                              >
                                Перезапустить сборку
                              </button>
                            )}
                          </div>
                          {build.libraries && (
                            <p className="w-fit m-0 overflow-y-auto p-2 bg-zinc-100 rounded-lg">
                              Подключенные библиотеки:
                              <br />
                              {build.libraries &&
                                build.libraries
                                  .split(' ')
                                  .map(function (item, idx) {
                                    return (
                                      <a
                                        href={item}
                                        key={idx}
                                        className="text-gray-600 text-nowrap underline underline-offset-4"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item}
                                        <br />
                                      </a>
                                    );
                                  })}
                            </p>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            {firmware.builds.length > 0 && <hr className="bg-gray-200"></hr>}
          </>
        ))}
      </div>
    </ModalLayout>
  );
}

export default FirmwareInfoModal;
