import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postDevices,
  fetchDevices as fetchDevicesApi,
  deleteDevice as deleteDeviceApi,
} from '../api/devices';
import useUserData from './useUserData';
import { reset, setDevices } from '../redux/device';
import { useAppContext } from '../contexts/AppContext';

export default function useDeviceManagement() {
  const dispatch = useDispatch();
  const { devices } = useSelector((state) => state.device);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const { refetchUserData } = useUserData();
  const { isInitialized } = useAppContext();

  const refreshDevices = useCallback(async () => {
    setIsLoading(true);
    try {
      const devices = await fetchDevicesApi();
      dispatch(setDevices(devices));
    } catch (error) {
      console.error('Error fetching devices:', error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const addNewDevice = useCallback(
    async (platform, name, wifi, wifiPassword) => {
      const deviceId = await postDevices(platform, name, wifi, wifiPassword);
      await refreshDevices();
      refetchUserData();
      return deviceId;
    },
    [refreshDevices, refetchUserData]
  );

  const removeDevice = useCallback(
    async (deviceId) => {
      await deleteDeviceApi(deviceId);
      await refreshDevices();
      dispatch(reset())
    },
    [refreshDevices]
  );

  const handleFileChange = useCallback((event) => {
    if (event.target.files) setFile(event.target.files[0]);
  }, []);

  return {
    devices,
    isLoading: isLoading || !isInitialized,
    addNewDevice,
    removeDevice,
    handleFileChange,
    file,
    refreshDevices
  };
}
