import { createSlice } from '@reduxjs/toolkit';

const modalsSlice = createSlice({
  name: 'user',
  initialState: {
    token: '',
    info: {},
    payments: [],
    activeView: 'credits',
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setInfo: (state, action) => {
      state.info = {
        ...state.info,
        ...action.payload,
      };
    },
    setPayments(state, action) {
      state.payments = action.payload;
    },
    addPayment(state, action) {
      state.payments = [action.payload, ...state.payments];
    },
    setActiveView(state, action) {
      state.activeView = action.payload;
    },
  },
});

export const { setInfo, setPayments, addPayment, setActiveView, setToken } =
  modalsSlice.actions;
export default modalsSlice.reducer;
