import React from 'react';

import { InstallButton } from 'esp-web-tools';

function ESPInstall({ manifest_url, disabled }) {
  return (
    <esp-web-install-button manifest={manifest_url}>
      <button
        className={`btn-primary h-full text-nowrap ${disabled ? 'text-gray-400 hover:bg-white' : ''}`}
        disabled={disabled}
        slot="activate"
      >
        Установить прошивку через USB
      </button>
      <span slot="unsupported" className="text-sm text-red-800">
        Ваш браузер не поддерживается для установки прошивки
        <br />
        (Web Serial работает только в Google Chrome или Microsoft Edge)
      </span>
    </esp-web-install-button>
  );
}

export default ESPInstall;
