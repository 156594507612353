import React, { useEffect, useState } from 'react';
import ModalLayout from '../ModalLayout';
import image from '../../../assets/yandex.svg';
import { updateDevice } from '../../../api/devices';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Компонент для отображения строки таблицы
const FieldRow = ({
  element,
  index,
  onEdit,
  onDelete,
  settingsTranslations,
  availableUnits,
}) => {
  return (
    <div className="grid grid-cols-[1fr_1fr_1fr_auto] items-center gap-4 border-b py-2 hover:bg-gray-50 transition-colors">
      <div>{element.name}</div>
      <div>{settingsTranslations[element.setting] || element.setting}</div>
      <div>
        {availableUnits[element.setting]?.find(
          (unit) => unit.value === element.unit
        )?.label || element.unit}
      </div>
      <div className="flex gap-5">
        <button
          onClick={() => onEdit(index)}
          className="text-gray-600 hover:text-blue-700 transition-colors"
          title="Редактировать"
        >
          <PencilIcon className="h-5 w-5" />
        </button>
        <button
          onClick={() => onDelete(index)}
          className="text-gray-600 hover:text-red-700 transition-colors"
          title="Удалить"
        >
          <TrashIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

// Компонент для формы добавления/редактирования
const FieldForm = ({
  settingsTranslations,
  selectedField,
  setSelectedField,
  selectedSetting,
  setSelectedSetting,
  selectedUnit,
  setSelectedUnit,
  floatFields,
  floatSettings,
  getAvailableUnits,
  handleAddElement,
  editingIndex,
}) => {
  return (
    <div className="flex flex-col justify-between space-x-0 space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0">
      <div className="w-full">
        <select
          value={selectedField}
          onChange={(e) => setSelectedField(e.target.value)}
          className="w-full border p-2 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Выберите поле</option>
          {floatFields.map((field) => (
            <option key={field.name} value={field.name}>
              {field.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full">
        <select
          value={selectedSetting}
          onChange={(e) => setSelectedSetting(e.target.value)}
          className="w-full border p-2 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
        >
          {floatSettings.map((setting) => (
            <option key={setting} value={setting}>
              {settingsTranslations[setting]}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full">
        <select
          value={selectedUnit}
          onChange={(e) => setSelectedUnit(e.target.value)}
          className="w-full border p-2 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
          disabled={!selectedField || !selectedSetting}
        >
          {getAvailableUnits(selectedSetting).map((unit) => (
            <option key={unit.value} value={unit.value}>
              {unit.label}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={handleAddElement}
        className={`w-full p-2 text-white transition-colors lg:w-fit ${
          editingIndex !== null
            ? 'bg-green-500 hover:bg-green-600'
            : !selectedField || !selectedSetting
              ? 'bg-gray-300'
              : 'bg-blue-500 hover:bg-blue-600'
        }`}
        disabled={!selectedField || !selectedSetting}
      >
        {editingIndex !== null ? 'Обновить' : 'Добавить'}
      </button>
    </div>
  );
};

// Компонент для отображения таблицы
const FieldTable = ({
  elements,
  onEdit,
  onDelete,
  settingsTranslations,
  availableUnits,
}) => {
  return (
    <div className="space-y-4 overflow-auto">
      <div className="grid grid-cols-[1fr_1fr_1fr_auto] gap-4 border-b pb-2 font-semibold">
        <div>Поле из состояния устройства</div>
        <div>Настройка</div>
        <div>Единица измерения</div>
        <div>Действия</div>
      </div>
      {elements.length > 0 ? (
        elements.map((element, index) => (
          <FieldRow
            key={index}
            element={element}
            index={index}
            onEdit={onEdit}
            onDelete={onDelete}
            settingsTranslations={settingsTranslations}
            availableUnits={availableUnits}
          />
        ))
      ) : (
        <div className="grid grid-cols-[1fr_1fr_1fr_auto] gap-4 border-b py-2">
          <div className="text-gray-400">—</div>
          <div className="text-gray-400">—</div>
          <div className="text-gray-400">—</div>
          <div className="text-gray-400">—</div>
        </div>
      )}
    </div>
  );
};

function DeviceYandex({ closeModal, deviceId }) {
  const [elements, setElements] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [selectedSetting, setSelectedSetting] = useState('meter');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);

  const device = useSelector((state) =>
    state.device.devices.find((d) => d.id === deviceId)
  );

  useEffect(() => {
    if (device?.yandex_mapping) {
      const initialElements = device.yandex_mapping.map((item) => ({
        name: item.field,
        setting: item.setting.instance,
        unit: item.setting.unit,
      }));
      setElements(initialElements);
    }
  }, [device]);

  useEffect(() => {
    const availableUnits = getAvailableUnits(selectedSetting);
    if (availableUnits.length > 0) {
      setSelectedUnit(availableUnits[0].value); // Set to the value of the first unit
    }
  }, [selectedSetting]);

  const schema = useSelector((state) => state.device.schema);

  // Фильтруем только поля с типом float
  const floatFields = schema.filter((field) => field.type === 'float');

  // Объект с переводами настроек
  const settingsTranslations = {
    meter: 'Универсальный счетчик',
    amperage: 'Текущее потребление тока',
    battery_level: 'Уровень заряда аккумулятора',
    co2_level: 'Уровень углекислого газа',
    electricity_meter: 'Показания счетчика электроэнергии',
    food_level: 'Уровень корма',
    gas_meter: 'Показания счетчика газа',
    heat_meter: 'Показания счетчика тепла',
    humidity: 'Влажность',
    illumination: 'Уровень освещенности',
    pm1_density: 'Уровень загрязнения PM1',
    pm2_5_density: 'Уровень загрязнения PM2.5',
    pm10_density: 'Уровень загрязнения PM10',
    power: 'Потребляемая мощность',
    pressure: 'Давление',
    temperature: 'Температура',
    tvoc: 'Уровень загрязнения органическими веществами',
    voltage: 'Напряжение',
    water_level: 'Уровень воды',
    water_meter: 'Показания счетчика воды',
  };

  // Объект с доступными единицами измерения для каждой настройки
  const availableUnits = {
    meter: [{ value: '', label: '-' }],
    amperage: [{ value: 'unit.ampere', label: 'Ампер' }],
    battery_level: [{ value: 'unit.percent', label: 'Процент' }],
    co2_level: [{ value: 'unit.ppm', label: 'Миллионные доли' }],
    electricity_meter: [{ value: 'unit.kilowatt_hour', label: 'Киловатт-час' }],
    food_level: [{ value: 'unit.percent', label: 'Процент' }],
    gas_meter: [{ value: 'unit.cubic_meter', label: 'Кубический метр' }],
    heat_meter: [{ value: 'unit.gigacalorie', label: 'Гигакалории' }],
    humidity: [{ value: 'unit.percent', label: 'Процент' }],
    illumination: [{ value: 'unit.illumination.lux', label: 'Люкс' }],
    pm1_density: [{ value: 'unit.density.mcg_m3', label: 'мкг/м3' }],
    pm2_5_density: [{ value: 'unit.density.mcg_m3', label: 'мкг/м3' }],
    pm10_density: [{ value: 'unit.density.mcg_m3', label: 'мкг/м3' }],
    power: [{ value: 'unit.power.watt', label: 'Ватт' }],
    pressure: [
      { value: 'unit.pressure.atm', label: 'Атмосфер' },
      { value: 'unit.pressure.pascal', label: 'Паскаль' },
      { value: 'unit.pressure.bar', label: 'Бар' },
      { value: 'unit.pressure.mmhg', label: 'Миллиметров ртутного столба' },
    ],
    temperature: [
      { value: 'unit.temperature.celsius', label: 'Градусов Цельсия' },
      { value: 'unit.temperature.kelvin', label: 'Кельвин' },
    ],
    voltage: [
      { value: 'unit.voltage.volt', label: 'Вольт' },
      { value: 'unit.voltage.kilovolt', label: 'Киловольт' },
    ],
    tvoc: [{ value: 'unit.density.mcg_m3', label: 'мкг/м3' }],
    water_level: [{ value: 'unit.percent', label: 'Процент' }],
    water_meter: [{ value: 'unit.cubic_meter', label: 'Кубический метр' }],
  };

  // Проверка на дублирование поля
  const isFieldDuplicate = (fieldName) => {
    return elements.some((element) => element.name === fieldName);
  };

  const handleAddElement = () => {
    if (selectedField && selectedSetting) {
      console.log(editingIndex);
      if (editingIndex === null && isFieldDuplicate(selectedField)) {
        toast.error('Поле уже добавлено в таблицу');
        return;
      }

      const selectedSchema = floatFields.find(
        (field) => field.name === selectedField
      );
      const newElement = {
        ...selectedSchema,
        setting: selectedSetting,
        unit: selectedUnit,
      };

      let updatedElements;
      if (editingIndex !== null) {
        updatedElements = [...elements];
        updatedElements[editingIndex] = newElement;
        setEditingIndex(null);
      } else {
        updatedElements = [...elements, newElement];
      }

      setElements(updatedElements);
      setSelectedField('');
      setSelectedSetting('');
      setSelectedUnit('');

      sendUpdateRequest(updatedElements);
    }
  };

  const handleEditElement = (index) => {
    const element = elements[index];
    setSelectedField(element.name);
    setSelectedSetting(element.setting || '');

    // Find the unit object that matches the element's unit value
    const availableUnits = getAvailableUnits(element.setting);
    const selectedUnitObject = availableUnits.find(
      (unit) => unit.value === element.unit
    );

    // Set the selectedUnit to the unit's value (not the object)
    setSelectedUnit(selectedUnitObject ? selectedUnitObject.value : '');
    setEditingIndex(index);
  };

  const handleDeleteElement = (index) => {
    const updatedElements = elements.filter((_, i) => i !== index);
    setElements(updatedElements);
    sendUpdateRequest(updatedElements);
  };

  const sendUpdateRequest = async (updatedElements) => {
    try {
      // Transform the elements into the required schema format
      const schema = updatedElements.map((element) => ({
        field: element.name,
        setting: {
          instance: element.setting,
          unit: element.unit ? element.unit : null,
        },
      }));

      // Send the schema to the backend
      await updateDevice(deviceId, { yandex_config: schema });
    } catch (error) {
      console.error('Failed to update device:', error);
    }
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  // Список доступных настроек для float полей
  const floatSettings = Object.keys(settingsTranslations);

  // Получение доступных единиц измерения для выбранной настройки
  const getAvailableUnits = (setting) => {
    return availableUnits[setting] || [];
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="ModalLayout space-y-10" onClick={handleModalClick}>
        <div className="flex flex-col justify-between mr-10 lg:flex-row">
          <div>
            <h3 className="text-lg font-bold md:text-2xl mb-2">
              Конфигурация для приложения умного дома от Яндекса
            </h3>
            <span>
              Настройка для чисел с точкой (float полей) согласно{' '}
              <a
                className="text-purple-800"
                href="https://yandex.ru/dev/dialogs/smart-home/doc/ru/concepts/float-instance"
              >
                документации Яндекса
              </a>
            </span>
          </div>
          <img className="bg-stone-600 max-w-[200px]" src={image} alt="image" />
        </div>
        <FieldTable
          elements={elements}
          onEdit={handleEditElement}
          onDelete={handleDeleteElement}
          settingsTranslations={settingsTranslations}
          availableUnits={availableUnits}
        />
        <FieldForm
          settingsTranslations={settingsTranslations}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          selectedSetting={selectedSetting}
          setSelectedSetting={setSelectedSetting}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          floatFields={floatFields}
          floatSettings={floatSettings}
          getAvailableUnits={getAvailableUnits}
          handleAddElement={handleAddElement}
          editingIndex={editingIndex}
        />
      </div>
    </ModalLayout>
  );
}

export default DeviceYandex;
