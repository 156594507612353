import React, { useEffect, useState } from 'react';
import ModalLayout from './ModalLayout';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/modals';
import { deviceRunBuild } from '../../api/devices';

function FirmwareBuildModal({
  deviceId,
  previousLibraries,
  previousFqbn,
  closeModal,
}) {
  const dispatch = useDispatch();
  const [fqbn, setFqbn] = useState(previousFqbn || '');
  const [libraries, setLibraries] = useState(previousLibraries || '');
  const [error, setError] = useState('');

  const device = useSelector((state) =>
    state.device.devices.find((d) => d.id === deviceId)
  );
  const availableFqbn = useSelector((state) => state.device.availableFqbn);
  const defaultFqbn = useSelector((state) => state.device.defaultFqbn);

  const repoPattern =
    /^https:\/\/github\.com\/[a-zA-Z0-9_.-]+\/[a-zA-Z0-9_.-]+\.git(?:#[a-zA-Z0-9_.-]*)?$/;

  useEffect(() => {
    setFqbn(previousFqbn || defaultFqbn[device.platform]);
  }, [device]);

  const handleInputChange = (event) => {
    setLibraries(event.target.value);
    setError('');
  };

  const runBuild = () => {
    deviceRunBuild(deviceId, fqbn, libraries);
  };

  const validateLibraries = (input) => {
    if (!input) {
      return true;
    }

    const repoStrings = input.split(/\s+/);

    for (const repo of repoStrings) {
      if (repo && !repoPattern.test(repo)) {
        setError(
          `Invalid repository format: ${repo}. Expected format: https://github.com/owner/repo.git#directory`
        );
        return false;
      }
    }

    return true;
  };

  const handleRunBuild = () => {
    if (validateLibraries(libraries)) {
      runBuild(fqbn, libraries);
      closeModal();
      dispatch(openModal('firmwareInfoModal'));
    }
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="rounded-lg bg-white p-6 shadow-lg">
        {/* Code Block */}
        <div className="mb-6 rounded-lg border border-gray-200 bg-gray-50 p-4">
          <pre className="whitespace-pre-wrap break-words font-mono text-sm text-gray-700">
            <code>
              <span className="text-blue-600">arduino-cli compile</span> \<br />
              &nbsp;&nbsp;--fqbn{' '}
              <select
                value={fqbn}
                onChange={(e) => setFqbn(e.target.value)}
                className="w-[200px] border px-1 rounded-lg shadow-sm focus:outline-none"
              >
                {availableFqbn[device.platform].map((unit) => (
                  <option
                    key={unit.code}
                    value={unit.code}
                    className={`${unit.code === defaultFqbn[device.platform] ? 'bg-blue-100' : ''}`}
                  >
                    {unit.name}
                  </option>
                ))}
              </select>{' '}
              <span className="text-gray-400">({fqbn})</span>
              <br />
              &nbsp;&nbsp;--library ArduinoJson/src \<br />
              &nbsp;&nbsp;--library pubsubclient/src \<br />
              &nbsp;&nbsp;
              <span className="text-gray-400">--library ...</span>
            </code>
          </pre>
        </div>

        {/* Libraries Input */}
        <div className="mb-6">
          <textarea
            value={libraries}
            onChange={handleInputChange}
            placeholder="Например: https://github.com/Arduino-IRremote/Arduino-IRremote.git#src&#10;Где #src - путь до исходных файлов библиотеки"
            className="w-full resize-y rounded-lg border border-gray-300 p-3 transition-all duration-200 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={4}
          />
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-4 rounded-lg border border-red-200 bg-red-50 p-3">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        {/* Run Build Button */}
        <button
          onClick={handleRunBuild}
          className="w-full px-6 rounded-xl shadow-md border border-green-300 bg-zinc-50 py-2 font-medium text-green-900 transition-all duration-100 hover:bg-green-50"
        >
          Запустить
        </button>
      </div>
    </ModalLayout>
  );
}

export default FirmwareBuildModal;
