import React, { useState, useRef, useEffect } from 'react';
import { startOTA } from '../../../api/devices';
import { useSelector } from 'react-redux';

const DeleteDeviceButton = ({ deviceId, removeDevice }) => {
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const buttonRef = useRef(null);
  const confirmationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        confirmationRef.current &&
        !confirmationRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsConfirmationVisible(false);
      }
    };

    if (isConfirmationVisible) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isConfirmationVisible]);

  const handleDeleteClick = () => {
    setIsConfirmationVisible(true);
  };

  const handleConfirm = () => {
    removeDevice(deviceId);
    setIsConfirmationVisible(false);
  };

  const handleCancel = () => {
    setIsConfirmationVisible(false);
  };

  return (
    <>
      <button
        id="deleteDeviceBtn"
        ref={buttonRef}
        className="btn-primary w-full sm:w-auto bg-red-50 hover:bg-red-200 lg:w-fit"
        onClick={handleDeleteClick}
      >
        Удалить
      </button>

      {isConfirmationVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            ref={confirmationRef}
            className="w-11/12 max-w-md rounded-lg bg-white p-6 shadow-lg"
          >
            <p className="mb-6 text-center text-lg font-medium text-gray-800">
              Вы уверены, что хотите удалить устройство?
            </p>
            <div className="flex justify-center gap-4">
              <button
                className="btn-primary w-24 bg-red-100 hover:bg-red-200"
                onClick={handleConfirm}
              >
                Да
              </button>
              <button
                className="btn-primary w-24 bg-gray-100 hover:bg-gray-200"
                onClick={handleCancel}
              >
                Нет
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ActionButton = ({ onClick, children, className = '', ...props }) => {
  return (
    <button
      className={`btn-primary w-full md:w-auto ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

function DeviceActions({
  removeDevice,
  deviceId,
  isFresh,
  openYandex,
  openTrigger,
  toggleIde,
  openFirmwareInfoModal,
  openBuildModal,
  openEventLogModal,
  openDeviceHistoryModal,
}) {
  const { firmware } = useSelector((state) => state.firmware);
  const firmwareReady = firmware?.find((e) =>
    e.builds.some((build) => build.status === 'completed')
  );

  const handleOTA = () => {
    startOTA(firmwareReady.id);
  };

  return (
    <div className="w-full gap-4 shadow-md  bg-gradient-to-t from-[#fff7e7] to-[#eeeeee] rounded-2xl p-4">
      {/* Left Section */}
      <div className="flex flex-col justify-normal gap-1 lg:justify-between lg:flex-row">
        <ActionButton onClick={toggleIde}>▼ Редактор прошивки</ActionButton>
        <ActionButton
          onClick={(event) => {
            event.stopPropagation();
            openBuildModal(event, deviceId);
          }}
        >
          Скомпилировать
        </ActionButton>
        <ActionButton
          onClick={(event) => {
            event.stopPropagation();
            openFirmwareInfoModal(event, deviceId);
          }}
          className={isFresh ? 'animate-pulse' : ''}
        >
          Прошивки
        </ActionButton>
        <ActionButton
          onClick={(event) => {
            event.stopPropagation();
            openDeviceHistoryModal(event, deviceId);
          }}
        >
          История состояний
        </ActionButton>
        <ActionButton
          onClick={(event) => {
            event.stopPropagation();
            openEventLogModal(event, deviceId);
          }}
        >
          Уведомления
        </ActionButton>
        <ActionButton onClick={openTrigger}>Триггеры</ActionButton>
        <ActionButton onClick={openYandex}>Яндекс</ActionButton>
        <DeleteDeviceButton deviceId={deviceId} removeDevice={removeDevice} />
      </div>
    </div>
  );
}

export default DeviceActions;
