import { createSlice } from '@reduxjs/toolkit';

const firmwareSlice = createSlice({
  name: 'firmware',
  initialState: {
    firmware: [],
  },
  reducers: {
    setFirmware(state, action) {
      state.firmware = action.payload;
    },
    updateFirmware(state, action) {
      const firmwareIndex = state.firmware.findIndex(
        (item) => item.id === action.payload.id
      );
      if (firmwareIndex !== -1) {
        state.firmware[firmwareIndex] = {
          ...state.firmware[firmwareIndex],
          ...action.payload,
        };
      }
    },
    addFirmware(state, action) {
      const { firmware_id, data } = action.payload;
      const existingFirmware = state.firmware.find(
        (item) => item.id === firmware_id.toString()
      );

      if (existingFirmware) {
        const existingBuildIndex = existingFirmware.builds.findIndex(
          (build) => build.id === data.id
        );

        if (existingBuildIndex !== -1) {
          existingFirmware.builds[existingBuildIndex] = data;
        } else {
          existingFirmware.builds.unshift(data);
        }
      }
    },
  },
});

export default firmwareSlice.reducer;

export const { setFirmware, addFirmware, updateFirmware } =
  firmwareSlice.actions;
