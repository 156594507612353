import React, { useEffect, useState } from 'react';
import ModalLayout from '../ModalLayout';
import { setTriggers } from '../../../redux/device';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import {
  createTrigger,
  updateTrigger,
  deleteTrigger,
  fetchTriggers,
} from '../../../api/trigger';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const DeviceTrigger = ({ triggers, closeModal, deviceId }) => {
  const initialElements = triggers
    ? triggers.map((trigger) => ({
        ...trigger,
        messageTemplate: trigger.message_template,
      }))
    : [];

  const [elements, setElements] = useState(initialElements);
  const [type, setType] = useState('TG');
  const [condition, setCondition] = useState('');
  const [messageTemplate, setMessageTemplate] = useState('');
  const [timeout, setTimeout] = useState('5');
  const [resetAfter, setResetAfter] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
        const triggers = await fetchTriggers(deviceId);
        dispatch(setTriggers(triggers));
      } catch (error) {
        console.error('Failed to fetch triggers:', error);
      }
    }
    if (loaded) {
      fetchData();
    }
    setLoaded(true);
  }, [elements, deviceId, dispatch, loaded]);

  const handleAddElement = async () => {
    if (!condition || !messageTemplate) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      if (editingIndex !== null) {
        const updatedTrigger = await updateTrigger(
          deviceId,
          elements[editingIndex].id,
          type,
          condition,
          messageTemplate,
          timeout,
          resetAfter
        );
        const updatedElements = [...elements];
        updatedElements[editingIndex] = {
          ...updatedTrigger,
          messageTemplate: updatedTrigger.message_template,
        };
        setElements(updatedElements);
        setEditingIndex(null);
      } else {
        const createdTrigger = await createTrigger(
          deviceId,
          type,
          condition,
          messageTemplate,
          timeout,
          resetAfter
        );
        setElements([
          ...elements,
          {
            ...createdTrigger,
            messageTemplate: createdTrigger.message_template,
          },
        ]);
      }
      resetForm();
    } catch (error) {
      console.error('Failed to save trigger:', error);
      alert('Failed to save trigger. Please try again.');
    }
  };

  const handleEditElement = (index) => {
    const element = elements[index];
    setType(element.type);
    setCondition(element.condition);
    setMessageTemplate(element.messageTemplate);
    setTimeout(element.timeout);
    setResetAfter(element.resetAfter);
    setEditingIndex(index);
  };

  const handleDeleteElement = async (index) => {
    try {
      const triggerId = elements[index].id;
      await deleteTrigger(deviceId, triggerId);
      const updatedElements = elements.filter((_, i) => i !== index);
      setElements(updatedElements);
    } catch (error) {
      console.error('Failed to delete trigger:', error);
      alert('Failed to delete trigger. Please try again.');
    }
  };

  const resetForm = () => {
    setType('TG');
    setCondition('');
    setMessageTemplate('');
    setTimeout('');
    setResetAfter(true);
    setEditingIndex(null);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="flex items-center justify-between pr-10">
        <h2 className="text-xl font-bold text-gray-800">
          Конфигурация нотификаций
        </h2>
        <a
          className="text-lg font-semibold text-blue-600 hover:text-blue-800"
          href="/docs"
        >
          Подробнее
        </a>
      </div>
      <div
        className="rounded-lg bg-zinc-100 p-6 shadow-lg"
        onClick={handleModalClick}
      >
        <div className="space-y-4 overflow-y-auto bg-white p-2">
          <div className="grid grid-cols-[1fr_1fr_1fr_1fr_1fr_auto] gap-4 pb-2 font-semibold text-gray-700">
            <div
              data-tooltip-id="type-tooltip"
              data-tooltip-content="Тип нотификации (например, Telegram)"
            >
              Тип
            </div>
            <div
              data-tooltip-id="condition-tooltip"
              data-tooltip-content="Условие, при котором срабатывает нотификация (например, D3 == 1)"
            >
              Условие
            </div>
            <div
              data-tooltip-id="message-template-tooltip"
              data-tooltip-content="Шаблон сообщения, которое будет отправлено"
            >
              Шаблон сообщения
            </div>
            <div
              data-tooltip-id="timeout-tooltip"
              data-tooltip-content="Минимальный интервал между срабатываниями (в секундах)"
            >
              Таймаут
            </div>
            <div
              data-tooltip-id="reset-after-tooltip"
              data-tooltip-content="Сбрасывать таймаут, если условие перестает выполняться"
            >
              Сбрасывать таймаут
            </div>
            <div
              data-tooltip-id="actions-tooltip"
              data-tooltip-content="Действия: редактировать или удалить триггер"
            >
              Действия
            </div>
          </div>
          {elements.length > 0 ? (
            elements.map((element, index) => (
              <div
                key={element.id}
                className="grid grid-cols-[1fr_1fr_1fr_1fr_1fr_auto] items-center gap-4 border-b border-gray-100 py-3 transition-colors hover:bg-gray-50"
              >
                <div className="text-gray-800">{element.type}</div>
                <div className="text-gray-800">{element.condition}</div>
                <div className="text-gray-800">{element.messageTemplate}</div>
                <div className="text-gray-800">{element.timeout}</div>
                <div className="text-gray-800">
                  {element.resetAfter ? 'Да' : 'Нет'}
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleEditElement(index)}
                    className="text-gray-500 transition-colors hover:text-blue-600"
                    title="Редактировать"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDeleteElement(index)}
                    className="text-gray-500 transition-colors hover:text-red-600"
                    title="Удалить"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="grid grid-cols-[1fr_1fr_1fr_1fr_1fr_auto] gap-4 py-3 text-gray-400">
              <div>—</div>
              <div>—</div>
              <div>—</div>
              <div>—</div>
              <div>—</div>
              <div>—</div>
            </div>
          )}
        </div>
        <div className="mt-6 flex flex-col justify-between gap-4 lg:flex-row">
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="w-full rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 lg:w-1/4"
          >
            <option value="TG">Telegram</option>
          </select>
          <input
            type="text"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            placeholder="D3 AND temperature > 20"
            className="w-full rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 lg:w-1/4"
          />
          <input
            type="text"
            value={messageTemplate}
            onChange={(e) => setMessageTemplate(e.target.value)}
            placeholder="Температура: { temperature }"
            className="w-full rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 lg:w-1/4"
          />
          <label className="flex items-center gap-2 lg:w-1/4">
            <input
              type="number"
              value={timeout}
              onChange={(e) => setTimeout(e.target.value)}
              placeholder="Таймаут (секунды)"
              className="w-full text-center rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 lg:w-1/4"
            />
            секунд
          </label>
          <label className="flex items-center gap-2 lg:w-1/4">
            <input
              type="checkbox"
              checked={resetAfter}
              onChange={(e) => setResetAfter(e.target.checked)}
              className="rounded border-gray-300 p-2"
            />
            Сбрасывать таймаут
          </label>
          <button
            onClick={handleAddElement}
            className={`w-full rounded-sm p-2 text-white shadow-[inset_0_-2px_3px_2px_rgba(0,0,0,0.2)] transition-colors lg:w-auto ${
              editingIndex !== null
                ? 'bg-green-500 hover:bg-green-600'
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {editingIndex !== null ? 'Обновить' : 'Добавить'}
          </button>
        </div>
      </div>
      <Tooltip id="type-tooltip" />
      <Tooltip id="condition-tooltip" />
      <Tooltip id="message-template-tooltip" />
      <Tooltip id="timeout-tooltip" />
      <Tooltip id="reset-after-tooltip" />
      <Tooltip id="actions-tooltip" />
    </ModalLayout>
  );
};

export default DeviceTrigger;
