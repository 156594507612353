import { API_URL } from '../constants/constants';
import { fetch } from './refreshToken.js';
import { toast } from 'react-toastify';

export async function fetchTransactions(page, perPage, activeView) {
  try {
    const response = await fetch(
      `${API_URL}/payments/transactions?offset=${page}&limit=${perPage}&type=${activeView}`,
      {
        method: 'GET',
      }
    );
    const data = await response;
    return data.body;
  } catch (error) {
    toast.error('Ошибка сети', { toastId: 'network_err' });
    console.error('Error fetching devices:', error);
  }
}

export async function createOrder(amount) {
  const data = { amount };
  try {
    const response = await fetch(`${API_URL}/payments/orders`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;' },
      body: JSON.stringify(data),
    });
    return response.body;
  } catch (error) {
    toast.error('Ошибка сети', { toastId: 'network_err' });
    console.error('Error creating order:', error);
    throw error;
  }
}
