import React, { useEffect, useState } from 'react';
import Logo from '../Logo';
import BalanceModal from '../Modals/BalanceModal';
import EventLogModal from '../Modals/EventLogModal';

const Header = ({ userData, onAddDevice, onLogout, isLoading, isFresh }) => {
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [isEventLogModalOpen, setIsEventLogModalOpen] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    setUnreadNotificationsCount(userData?.unseen_notifications || 0);
  }, [userData]);

  const openBalanceModal = () => setIsBalanceModalOpen(true);
  const closeBalanceModal = () => setIsBalanceModalOpen(false);

  const openEventLogModal = () => setIsEventLogModalOpen(true);
  const closeEventLogModal = () => setIsEventLogModalOpen(false);

  // Function to update the unread notifications count
  const updateUnreadNotificationsCount = (count) => {
    setUnreadNotificationsCount(count);
  };

  return (
    <header className="w-full">
      {/* Logo Section */}
      <div
        className="flex min-h-[12dvh] flex-col items-center justify-center text-center"
        id="logo-container"
      >
        <div id="logo-landing" className="rounded">
          <div>
            <Logo />
          </div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="mb-2 px-2">
        <div className="flex flex-col items-start justify-between gap-2 px-2 text-xs md:flex-row md:items-center md:text-sm lg:px-0">
          <button
            type="button"
            className="btn-header transition-none shadow-none border-2 md:hidden"
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            {isAccordionOpen ? 'Скрыть' : 'Меню'}
          </button>

          <div
            className={`${
              isAccordionOpen ? 'block' : 'hidden'
            } order-2 flex w-full flex-col gap-2 md:order-1 md:flex md:w-auto md:flex-row md:items-center`}
          >
            <button
              type="button"
              id="createButton"
              className={`btn-header text-nowrap ${isFresh ? 'animate-pulse' : ''}`}
              onClick={onAddDevice}
            >
              Добавить устройство
            </button>
            <button
              type="button"
              className="btn-header relative text-nowrap"
              onClick={openEventLogModal}
            >
              <span className="flex items-center gap-1">
                <span className="w-full">Журнал событий</span>
                {unreadNotificationsCount > 0 && (
                  <span className="notification-badge">
                    {unreadNotificationsCount}
                  </span>
                )}
              </span>
            </button>
            <a
              href="/docs"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-header text-nowrap hidden md:block"
            >
              Документация
            </a>
          </div>

          <div
            className={`${
              isAccordionOpen ? 'block' : 'hidden'
            } order-3 flex w-full flex-col gap-2 md:order-1 md:flex md:w-auto md:flex-row md:items-center`}
          >
            <button
              type="button"
              className="btn-header w-full text-nowrap md:w-auto"
              onClick={openBalanceModal}
            >
              Баланс:{' '}
              {isLoading ? (
                <span className="inline-block h-4 w-12 animate-pulse rounded bg-gray-200"></span>
              ) : (
                <span>{userData?.balance || 0} ₽</span>
              )}
            </button>
            <button
              type="button"
              className="btn-header hover:bg-red-200"
              onClick={onLogout}
            >
              Выход
            </button>
          </div>
        </div>

        {/* Balance Modal */}
        {isBalanceModalOpen && (
          <BalanceModal closeModal={closeBalanceModal} userData={userData} />
        )}

        {/* Event Log Modal */}
        {isEventLogModalOpen && (
          <EventLogModal
            closeModal={closeEventLogModal}
            updateUnreadNotificationsCount={updateUnreadNotificationsCount} // Pass the function to update the count
          />
        )}
      </div>
      <hr className="m-0 h-[1px] bg-gray-300 p-0" />
    </header>
  );
};

export default Header;
