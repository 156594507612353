import React, { useEffect, useState } from 'react';
import ModalLayout from './ModalLayout';
import { BOT_NAME } from '../../constants/constants';
import { pollAccessKey } from '../../api/login';
import { useDispatch } from 'react-redux';
import useDeviceManagement from '../../hooks/useDeviceManagement';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';

function LoginModal({ closeModal, token }) {
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Скопировано в буфер обмена!');
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
      });
  };
  return (
    <ModalLayout closeModal={closeModal} className="!min-w-0 !min-h-0">
      <h1 className="font-bold text-lg text-blue-800">SSO</h1>
      <div className="p-5 space-y-5 text-sm lg:text-lg">
        <h1>
          Для авторизации отправьте Telegram боту{' '}
          <span
            className="text-green-800 text-lg cursor-pointer"
            onClick={() => handleCopyToClipboard(`@${BOT_NAME}`)}
          >
            @{BOT_NAME}
          </span>{' '}
          сообщение или отсканируйте QR код
        </h1>
        <div
          onClick={() => handleCopyToClipboard(`/start ${token}`)}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm bg-gray-100 cursor-pointer overflow-y-hidden text-nowrap "
        >
          <span className="text-blue-700">/start</span> {token}
        </div>
        <span className="text-center text-xs text-gray-400">
          Нажмите для копирования в буфер обмена
        </span>
        <div className="flex justify-center">
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '128px', width: '100%' }}
            value={`https://t.me/${BOT_NAME}?start=${token}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
    </ModalLayout>
  );
}

export default LoginModal;
