import React from 'react';
import { useDispatch } from 'react-redux';
import { setDeviceId, setState, setTriggers } from '../../redux/device';
import { openModal } from '../../redux/modals';
import './Devices.css';
import { getFirmware, getState } from '../../api/devices';
import { setFirmware, updateFirmware } from '../../redux/firmware';
import { fetchTriggers } from '../../api/trigger';

const SkeletonLoader = () => (
  <div className="flex min-h-[200px] animate-pulse flex-col justify-between rounded-xl border border-gray-200 bg-white p-4 shadow-sm">
    <div className="h-6 rounded bg-gray-200"></div>
    <div className="h-4 rounded bg-gray-200"></div>
    <div className="h-4 rounded bg-gray-200"></div>
    <div className="h-8 rounded bg-gray-200"></div>
    <div className="h-8 rounded bg-gray-200"></div>
  </div>
);

const DeviceCard = ({ device, onClick }) => (
  <div
    className="device-card transform cursor-pointer p-4 transition-all duration-75"
    onClick={device.active ? onClick : undefined}
    disabled={!device.active}
  >
    {device.group && (
      <span className="device-card-group absolute right-2 top-[-0.5rem] rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-semibold text-gray-600 shadow-sm">
        {device.group}
      </span>
    )}
    <div className="mb-4">
      <span className="text-xl font-semibold text-gray-800">{device.name}</span>
    </div>
    {/* <hr className="my-2 border-t border-gray-100" /> */}
    <div className="space-y-2">
      <div className="flex justify-between">
        <span className="text-xs font-medium text-gray-500">Платформа:</span>
        <span className="text-xs text-gray-800">
          {device.platform.toUpperCase()}
        </span>
      </div>
      <div className="flex justify-between">
        <span className="text-xs font-medium text-gray-500">Статус:</span>
        {!device.connected ? (
          <span
            className={`text-xs ${device.active ? 'text-gray-500' : 'text-red-600'}`}
          >
            {device.active ? 'Не подключено' : 'Не оплачено'}
          </span>
        ) : (
          <span className="text-xs text-green-600">Подключено</span>
        )}
      </div>
      <div className="flex justify-between">
        <span className="text-xs font-medium text-gray-500">Тариф:</span>
        <span className="text-xs text-gray-800">
          {device.tariff_daily} ₽/сут.
        </span>
      </div>
    </div>
  </div>
);

function Devices({ devices = [] }) {
  const dispatch = useDispatch();

  const handleOpenDeviceModal = async (deviceId) => {
    const device = devices.find((d) => d.id === deviceId);
    dispatch(setDeviceId(deviceId));
    dispatch(setState(device.state));
    dispatch(openModal('deviceModal'));
    const [firmware, state, triggers] = await Promise.all([
      getFirmware(deviceId),
      getState(deviceId),
      fetchTriggers(deviceId),
    ]);
    dispatch(setState(state));
    dispatch(setFirmware(firmware));
    dispatch(setTriggers(triggers));
  };

  return (
    <div id="deviceListSection" className="relative w-full">
      <div className="w-full overflow-y-auto p-6">
        <div className="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {devices.length === 0
            ? Array.from({ length: 5 }).map((_, index) => (
                <SkeletonLoader key={index} />
              ))
            : devices.map((device) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onClick={() => handleOpenDeviceModal(device.id)}
                />
              ))}
        </div>
      </div>
    </div>
  );
}

export default Devices;
