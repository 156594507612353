import { createSlice } from '@reduxjs/toolkit';

export function getControlsSchema(state) {
  function getType(value) {
    if (typeof value === 'boolean') {
      return 'button';
    } else if (typeof value === 'number') {
      return Number.isInteger(value) ? 'int' : 'float';
    } else {
      return 'str';
    }
  }

  if (state) {
    return Object.entries(state)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => ({
        type: getType(value),
        name: key,
        editable: true,
      }));
  }
  return [];
}

const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    deviceId: null,
    devices: [],
    triggers: [],
    state: {},
    schema: [],
    imageSrc: null,
    availableFqbn: [],
    defaultFqbn: {},
  },
  reducers: {
    setAvailableFqbn(state, action) {
      state.availableFqbn = action.payload;
    },
    setDefaultFqbn(state, action) {
      state.defaultFqbn = action.payload;
    },
    setDevices(state, action) {
      state.devices = action.payload;
    },
    setTriggers(state, action) {
      state.triggers = action.payload;
    },
    setImageSrc(state, action) {
      state.imageSrc = action.payload;
    },
    setState(state, action) {
      state.state = action.payload;
      state.schema = getControlsSchema(action.payload);
    },
    reset(state, action) {
      state.triggers = [];
      state.state = {};
      state.schema = [];
      state.imageSrc = null;
    },
    setDeviceId(state, action) {
      state.deviceId = action.payload;
    },
    setStatus(state, action) {
      const { device_id, status } = action.payload;
      const deviceIndex = state.devices.findIndex(
        (device) => device.id === device_id
      );

      if (deviceIndex !== -1) {
        state.devices[deviceIndex].connected = status;
      }
    },
    setBuild(state, action) {
      const { device_id, build_id } = action.payload;
      const deviceIndex = state.devices.findIndex(
        (device) => device.id === device_id
      );

      if (deviceIndex !== -1) {
        state.devices[deviceIndex].current_build = build_id;
      }
    },
    updateDeviceField(state, action) {
      const { deviceId, field, value } = action.payload;
      const deviceIndex = state.devices.findIndex(
        (device) => device.id === deviceId
      );

      if (deviceIndex !== -1) {
        state.devices[deviceIndex][field] = value;
      }
    },
  },
});

export const {
  setAvailableFqbn,
  setDefaultFqbn,
  setDevices,
  setState,
  setTriggers,
  setDeviceId,
  setStatus,
  setBuild,
  reset,
  setImageSrc,
  updateDeviceField,
} = deviceSlice.actions;
export default deviceSlice.reducer;
