import { API_URL } from '../constants/constants';
import { fetch } from './refreshToken.js';
import { toast } from 'react-toastify';

export async function fetchTriggers(deviceId) {
  try {
    const response = await fetch(`${API_URL}/triggers/${deviceId}`, {
      method: 'GET',
    });
    return response.body.triggers;
  } catch (error) {
    toast.error('Ошибка сети', { toastId: 'network_err' });
    console.error('Error fetching triggers:', error);
    throw error;
  }
}

export async function createTrigger(
  deviceId,
  type,
  condition,
  messageTemplate,
  timeout,
  resetAfter
) {
  if (timeout === '') {
    timeout = null;
  }
  const trigger = {
    type,
    condition,
    message_template: messageTemplate,
    timeout,
    resetAfter,
  };
  try {
    const response = await fetch(`${API_URL}/triggers/${deviceId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;' },
      body: JSON.stringify(trigger),
    });
    return response.body;
  } catch (error) {
    if (error.status == 429) {
      toast.error(error?.body?.message);
      return null;
    }
    toast.error(error?.body?.detail[0]?.msg);
    console.error('Error creating trigger:', error);
    throw error;
  }
}

export async function updateTrigger(
  deviceId,
  triggerId,
  type,
  condition,
  messageTemplate,
  timeout,
  resetAfter
) {
  if (timeout === '') {
    timeout = null;
  }
  const trigger = {
    type,
    condition,
    message_template: messageTemplate,
    timeout,
    resetAfter,
  };
  try {
    const response = await fetch(
      `${API_URL}/triggers/${deviceId}/${triggerId}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;' },
        body: JSON.stringify(trigger),
      }
    );
    return response.body;
  } catch (error) {
    toast.error(error?.body?.detail[0]?.msg);
    console.error('Error update trigger:', error);
    throw error;
  }
}

export async function deleteTrigger(deviceId, triggerId) {
  try {
    const response = await fetch(
      `${API_URL}/triggers/${deviceId}/${triggerId}`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json;' },
      }
    );
    return response.body;
  } catch (error) {
    toast.error('Ошибка сети', { toastId: 'network_err' });
    console.error('Error delete trigger:', error);
    throw error;
  }
}
