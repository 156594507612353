import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ModalLayout from './ModalLayout';
import throttle from 'lodash.throttle';
import { fetchHistory, fetchHistoryForChart } from '../../api/devices';
import ReactJsonPretty from 'react-json-pretty';
import ChartComponent from './ChartComponent';
import { ChartBarIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import { getControlsSchema } from '../../redux/device';

// Custom theme matching your app's style
const jsonTheme = {
  main: 'line-height:1.3;color:#4a5568;background:transparent;',
  key: 'color:#2b6cb0;',
  string: 'color:#2f855a;',
  value: 'color:#6b46c1;',
  boolean: 'color:#c53030;',
  number: 'color:#2b6cb0;',
  bracket: 'color:#718096;',
  colon: 'color:#718096;',
};

// Loading skeleton component
const StateSkeleton = () => (
  <div className="space-y-4">
    {[...Array(10)].map((_, i) => (
      <div key={i} className="animate-pulse space-y-2">
        <div className="h-4 w-1/4 rounded bg-gray-200"></div>
        <div className="h-8 w-full rounded bg-gray-100"></div>
      </div>
    ))}
  </div>
);

// Table to display state history
const StateHistoryTable = ({ history }) => {
  return (
    <div className="h-full overflow-auto rounded-lg border border-gray-200">
      <table className="w-full table-fixed">
        <colgroup>
          <col className="w-[120px] sm:w-[180px]" />
          <col />
        </colgroup>
        <thead className="sticky top-0 bg-gray-50">
          <tr>
            <th className="border-b p-2 text-left text-xs sm:text-sm font-medium text-gray-500">
              Время
            </th>
            <th className="border-b p-2 text-left text-xs sm:text-sm font-medium text-gray-500">
              Состояние
            </th>
          </tr>
        </thead>
        <tbody>
          {history.map((entry, index) => {
            const typeOrder = ['float', 'int', 'str'];
            const groupedElements = getControlsSchema(entry.msg).reduce(
              (acc, element) => {
                if (!acc[element.type]) {
                  acc[element.type] = [];
                }
                acc[element.type].push(element);
                return acc;
              },
              {}
            );

            return (
              <tr key={index} className="hover:bg-gray-50">
                <td className="border-b p-2 font-mono text-xs sm:text-sm text-gray-600">
                  {new Date(entry.timestamp_ms).toLocaleString('ru-RU')}
                </td>
                <td className="border-b p-2">
                  {/* Inputs and Buttons */}
                  <div className="flex flex-col sm:flex-row gap-4">
                    {/* Input Fields */}
                    <div className="flex flex-wrap gap-4">
                      {typeOrder.map((type) =>
                        groupedElements[type]?.map((element, index) => {
                          if (
                            type === 'float' ||
                            type === 'int' ||
                            type === 'str'
                          ) {
                            return (
                              <div
                                key={index}
                                className="flex-1 min-w-[120px] sm:min-w-[150px]"
                              >
                                <label className="block text-xs font-medium text-gray-700 mb-1">
                                  {element.name} ({type}):
                                </label>
                                <input
                                  type="text"
                                  className="w-full rounded-lg border border-gray-200 p-2 text-sm bg-gray-100 cursor-default"
                                  value={entry.msg[element.name]}
                                  readOnly
                                />
                              </div>
                            );
                          }
                          return null;
                        })
                      )}
                    </div>

                    {/* Buttons */}
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                      {groupedElements['button']?.map((element, index) => {
                        const isOn = entry.msg[element.name];
                        return (
                          <button
                            key={index}
                            className={`w-full rounded-lg border border-gray-200 p-2 text-sm font-medium shadow-[inset_0_-2px_1px_rgba(0,0,0,0.15)] transition-colors duration-75 hover:shadow-none ${
                              isOn
                                ? 'bg-green-100 text-green-900 hover:bg-green-200'
                                : 'bg-red-100 text-red-900 hover:bg-red-200'
                            }`}
                            disabled={true}
                          >
                            {element.name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function DeviceStateModal({ closeModal, deviceId }) {
  const [stateHistory, setStateHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [viewMode, setViewMode] = useState('table'); // 'table' or 'chart'
  const [selectedFields, setSelectedFields] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [chartData, setChartData] = useState(null);
  const [aggregation, setAggregation] = useState(null);
  const [aggregationMethod, setAggregationMethod] = useState('mean');
  const [availableFields, setAvailableFields] = useState([]);

  useEffect(() => {
    if (viewMode === 'chart') {
      setItemsPerPage(100);
    } else {
      setItemsPerPage(10);
    }
  }, [viewMode]);

  const fetchChartData = useCallback(
    throttle(async () => {
      if (viewMode !== 'chart' || selectedFields.length === 0) return;

      try {
        setLoading(true);
        const fromDate = dateFrom ? new Date(dateFrom) : null;
        const toDate = dateTo ? new Date(dateTo) : null;

        const response = await fetchHistoryForChart(
          deviceId,
          selectedFields.join(','),
          itemsPerPage,
          fromDate,
          toDate,
          aggregation,
          aggregationMethod
        );
        setChartData(response);
        setAvailableFields(response?.available_fields || []);
      } catch (err) {
        console.error('Error fetching chart data:', err);
        setError('Не удалось загрузить данные для графика');
      } finally {
        setLoading(false);
      }
    }, 2000),
    [
      deviceId,
      selectedFields,
      dateFrom,
      dateTo,
      itemsPerPage,
      viewMode,
      aggregation,
      aggregationMethod,
    ]
  );

  const fetchStateHistory = useCallback(
    throttle(async () => {
      if (viewMode !== 'table') return;

      try {
        setLoading(true);
        const response = await fetchHistory(
          deviceId,
          (currentPage - 1) * itemsPerPage,
          itemsPerPage,
          selectedFields,
          dateFrom ? new Date(dateFrom).getTime() : undefined,
          dateTo ? new Date(dateTo).getTime() : undefined
        );
        setStateHistory(response.data || []);
        setAvailableFields(response?.available_fields || []);
        setTotalPages(Math.ceil(response.total_records / itemsPerPage));
        if (currentPage > totalPages) {
          setCurrentPage(1);
        }
      } catch (err) {
        console.error('Error fetching state history:', err);
        setError('Не удалось загрузить историю состояний');
      } finally {
        setLoading(false);
      }
    }, 2000),
    [
      deviceId,
      selectedFields,
      currentPage,
      dateFrom,
      dateTo,
      itemsPerPage,
      viewMode,
    ]
  );

  useEffect(() => {
    setError(null);
    if (viewMode === 'chart') {
      fetchChartData();
    } else {
      fetchStateHistory();
    }
  }, [fetchChartData, fetchStateHistory, viewMode]);

  const handleRefresh = () => {
    setLoading(true);
    setError(null);
    if (viewMode === 'chart') {
      fetchChartData();
    } else {
      fetchStateHistory();
    }
  };

  const handlePageInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0 && value <= totalPages) {
      setCurrentPage(value);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setItemsPerPage(value);
    }
  };

  const handleAggregationChange = (e) => {
    setAggregation(e.target.value);
  };

  const handleAggregationMethodChange = (e) => {
    setAggregationMethod(e.target.value);
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="space-y-4">
        {/* Header with view toggle */}
        <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between pr-8">
          <h2 className="text-lg font-medium">История состояний устройства</h2>
          <div className="flex items-center gap-2">
            <div className="flex rounded border">
              <button
                onClick={() => setViewMode('table')}
                className={`flex items-center gap-1 px-2 sm:px-3 py-1.5 text-sm sm:text-base ${
                  viewMode === 'table' ? 'bg-gray-100' : 'hover:bg-gray-50'
                }`}
              >
                <TableCellsIcon className="h-4 w-4" />
                <span>Таблица</span>
              </button>
              <button
                onClick={() => setViewMode('chart')}
                className={`flex items-center gap-1 px-2 sm:px-3 py-1.5 text-sm sm:text-base ${
                  viewMode === 'chart' ? 'bg-gray-100' : 'hover:bg-gray-50'
                }`}
              >
                <ChartBarIcon className="h-4 w-4" />
                <span>График</span>
              </button>
            </div>
            <button
              onClick={() => {
                setDateFrom('');
                setDateTo('');
                setCurrentPage(1);
                setSelectedFields([]);
                setLoading(false);
                setAggregation(null);
                setAggregationMethod('mean');
              }}
              className="btn-header w-fit h-fit hover:bg-red-100"
            >
              Сбросить
            </button>{' '}
            <button
              onClick={handleRefresh}
              className="btn-header px-3 sm:px-4"
              disabled={loading}
            >
              Обновить
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="flex flex-col gap-4">
          {/* Date inputs */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="flex flex-col gap-1">
              <span className="text-sm text-gray-500">От:</span>
              <input
                type="datetime-local"
                className="w-full rounded border border-gray-200 px-2 py-1.5 text-sm"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm text-gray-500">До:</span>
              <input
                type="datetime-local"
                className="w-full rounded border border-gray-200 px-2 py-1.5 text-sm"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </div>
          </div>

          {/* Items per page input */}
          <div className="flex flex-col gap-1">
            <span className="text-sm text-gray-500">
              Элементов на странице:
            </span>
            <input
              type="number"
              min="1"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="w-full rounded border border-gray-200 px-2 py-1.5 text-sm"
            />
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <label className="text-sm text-gray-500">Поля для отображения</label>
          <div className="flex flex-wrap gap-2 mt-2">
            {availableFields.map((field) => (
              <label key={field} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedFields.includes(field)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedFields((prev) => [...prev, field]);
                    } else {
                      setSelectedFields((prev) =>
                        prev.filter((f) => f !== field)
                      );
                    }
                  }}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm truncate">{field}</span>
              </label>
            ))}
          </div>
        </div>

        {viewMode === 'chart' && (
          <div className="flex flex-col sm:flex-row gap-6 rounded-lg border border-gray-200 p-4">
            <div className="w-full sm:w-1/2">
              <label htmlFor="aggregation" className="text-sm text-gray-500">
                Ресемплинг (сек)
              </label>
              <input
                id="aggregation"
                type="number"
                min="1"
                value={aggregation}
                placeholder="Интервал"
                onChange={handleAggregationChange}
                className="w-full rounded-lg border border-gray-200 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="w-full sm:w-1/2">
              <label
                htmlFor="aggregationMethod"
                className="text-sm text-gray-500 mt-4"
              >
                Метод ресемплинга
              </label>
              <select
                id="aggregationMethod"
                value={aggregationMethod}
                onChange={handleAggregationMethodChange}
                className="w-full rounded-lg border border-gray-200 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="mean">Среднее (Mean)</option>
                <option value="median">Медиана (Median)</option>
                <option value="sum">Сумма (Sum)</option>
                <option value="last">Последнее значение (Last)</option>
              </select>
            </div>
          </div>
        )}

        {/* Content */}
        <div className="h-[60vh]">
          {loading ? (
            <StateSkeleton />
          ) : error ? (
            <div className="flex h-full items-center justify-center text-red-500">
              {error}
            </div>
          ) : stateHistory.length === 0 ? (
            <div className="flex h-full items-center justify-center text-gray-500">
              Нет данных о состояниях устройства
            </div>
          ) : viewMode === 'table' ? (
            <StateHistoryTable history={stateHistory} />
          ) : (
            <div className="h-full p-4">
              {selectedFields.length > 0 && chartData ? (
                <ChartComponent data={chartData} />
              ) : (
                <div className="flex h-full items-center justify-center text-gray-500">
                  Выберите поля для отображения на графике
                </div>
              )}
            </div>
          )}
        </div>

        {/* Pagination (only show for table view) */}
        {viewMode === 'table' && totalPages > 1 && (
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <div className="flex items-center gap-2">
              <button
                onClick={() => setCurrentPage((p) => p - 1)}
                disabled={currentPage === 1 || loading}
                className="btn-header px-3 sm:px-4 disabled:opacity-50"
              >
                ←
              </button>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  min={1}
                  max={totalPages}
                  value={currentPage}
                  onChange={handlePageInputChange}
                  className="w-12 sm:w-16 rounded border border-gray-200 px-2 py-1 text-center text-sm"
                />
                <span className="text-sm text-gray-500">из {totalPages}</span>
              </div>
              <button
                onClick={() => setCurrentPage((p) => p + 1)}
                disabled={currentPage === totalPages || loading}
                className="btn-header px-3 sm:px-4 disabled:opacity-50"
              >
                →
              </button>
            </div>
          </div>
        )}
      </div>
    </ModalLayout>
  );
}

export default DeviceStateModal;
